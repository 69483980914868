import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/organisms/seo"
import Layout from "../components/layout-archives"

class tagTemplate extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const edges = data.allMarkdownRemark.edges
    const tagName = this.props.location.pathname.replace(/\//g,'')

    return (
      <>
        <SEO title={`関連タグ：${tagName}の記事一覧 | ${siteTitle}`} />
        <Layout
          location={this.props.location}
          title={`関連タグ：${tagName}の記事リストです`}
          edges={edges}
          >
        </Layout>
      </>
    )
  }
}

export default tagTemplate

export const pageQuery = graphql`
  query tagList($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tag: { eq: $slug } } },
      sort: { fields: [fields___slug], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            digest
            category
            tag
            img {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            giphy
          }
          parent {
            ... on File {
              birthTime(formatString: "YYYY-MM-DD")
              modifiedTime(formatString: "YYYY-MM-DD")
            }
          }
        }
      }
    }
  }
`
