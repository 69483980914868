import React from "react"
// import { rhythm } from "../utils/typography"
import Footer from "./atoms/footer"
import Sitename from "./moleculs/siteTitle"
import Heading from "./atoms/heading"
import Nav from "./organisms/navigation"
import GridCards from "./organisms/gridCards"
import Bio from "./organisms/bio"
import {ProvideMediaMatchers, MediaMatcher} from "react-media-match"


class Layout extends React.Component {
  render() {
    const { location, title, edges } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <>
      {/*<ProvideMediaMatchers>
        <MediaMatcher
          mobile={"mobile"}
          tablet={"tablet"}
          desktop={"render desktop"}
        />
      </ProvideMediaMatchers>*/}
        <header>
          <Sitename location={location} rootPath={rootPath} />
          <Nav className={`navi text-4xl`} />
        </header>

        <main>
          <div className="w-5/6 mx-auto sm:w-1/2 lg:w-1/2">
            <div className="mb-8">
              <Heading tagName="h1" size="text-lg">{title}</Heading>
            </div>
            <GridCards edges={edges} />
            <Bio />
          </div>
        </main>
        <div className="skewer"></div>
        <Footer />
      </>
    )
  }
}

export default Layout
